<script setup lang="ts">
import type { TheSidebarPropMenus } from '@/components/layout/TheSidebar/types'

const { t } = useI18n()

const menus = computed<TheSidebarPropMenus>(() => {
  const routes = [
    {
      title: t('health.continuousCare.title'),
      path: '/health',
      exact: true,
      event: 'Care Queue',
    },
    {
      title: t('health.customers.list.title'),
      path: '/health/customers',
      event: 'Access Customers List',
    },
    {
      title: t('events.notificationTitle'),
      path: '/health/notifications',
      event: 'Access Notifications List',
    },
    {
      title: t('events.reminderTitle'),
      path: '/health/reminders',
      event: 'Access Reminders List',
    },
    {
      title: t('health.dashboardTitle'),
      path: '/health/dashboard',
      exact: true,
      event: 'Access Dashboard',
    },
  ]

  return routes
})
</script>

<template>
  <div class="flex">
    <TheSidebar class="top-0 z-10 hidden h-screen md:fixed md:flex" :menus="menus" />
    <TheNavbar class="fixed left-0 right-0 z-10" />
    <TheTabBar class="md:hidden" :menus="menus" />
    <main
      class="mb-[56px] mt-[60px] max-w-full flex-1 transform-gpu p-2 transition-all will-change-transform md:ml-[240px] md:max-w-[calc(100%-240px)]"
    >
      <slot />
    </main>
  </div>
</template>
